import React, { useState,useEffect } from "react";
 
import { NavLink } from "react-router-dom";

const Nav = () => {
  const [show, setShow] = useState(true);
  const [scrol,setScroll]=useState(false)

 
 
  const openNav = () => {
    if(window.innerWidth<992){
      setShow(() => !show);
    }
    
  };

  

  useEffect(() => {    
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 110);
    });
  }, []);


  return (
    <>

<div className={scrol?`bannerBefore`:`bannerBefore hide`}>
            <div className="icons">
              <div className="iconContainer facebook">
                <a href="http://www.facbook.com/topdogcontractors">
                  <i className="fa-brands fa-facebook-f "></i>
                </a>
              </div>
              {/* <div className="iconContainer linkedIn">
                <a href="#">
                  <i className="fa-brands fa-linkedin-in "></i>
                </a>
              </div> */}

              <div className="iconContainer wifi">
                <a href="https://twitter.com/tdcontractors">
                <i class="fa-brands fa-twitter"></i>
                </a>
              </div>

              <div className="iconContainer phone">
                <a href="tel:919-373-2882">
                  <i className="fa-solid fa-phone "></i>
                </a>
              </div>

              {/*               
              <div className="iconContainer phone">
                <a href="#">
                <i class="fa-brands fa-instagram"></i>
                </a>
              </div> */}
            </div>
</div>

<section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope-fill"></i>
            <a href="mailto: info@topdog.contractors"> info@topdog.contractors</a>
            <i className="bi bi-phone-fill phone-icon"></i>
            <a href="tel:919-373-2882">919-373-2882</a>
           
          </div>
          <div className="social-links d-none d-md-block">
            <a href="https://twitter.com/tdcontractors" className="twitter">
              <i className="bi bi-twitter"></i>
            </a>
            <a href="http://www.facbook.com/topdogcontractors" className="facebook">
              <i className="bi bi-facebook"></i>
            </a>
            {/* <a href="#" className="instagram">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="#" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </a> */}
          </div>
        </div>
      </section>
      <header id="header" className={scrol?`d-flex align-items-center fixed-top `: `d-flex align-items-center`}>
        <div className="container d-flex align-items-center justify-content-between">
          <NavLink to="/" className="logo">
            <img
              src={require("../../assets/img/logo.png")}
              alt=""
              className="img-fluid"
            />
          </NavLink>

          <nav id="navbar" className={show ? `navbar ` : ` navbar-mobile`}>
            <ul>
              <li>
                <NavLink
                  
                  className="nav-link scrollto"
                  to="/"
                  onClick={openNav}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link scrollto"
                  
                  to="/#about"
                   
                  onClick={openNav}
                >
                  About
                </NavLink>
              </li>
              <li className="dropdown">
                <NavLink to="/#services" onClick={openNav}>
                  <span>Services</span> <i className="bi bi-chevron-down"></i>
                </NavLink>
                <ul>
                  <li>
                    <NavLink to="/remodeling" onClick={openNav}>
                      Remodeling
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/restoration" onClick={openNav}>
                      Restoration
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/repair" onClick={openNav}>
                      Home Repair
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/carpentry" onClick={openNav}>
                      Carpentry
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/smart-home" onClick={openNav}>
                      Smart Home
                    </NavLink>
                  </li>


                  
                  <li>
                    <NavLink to="/commercial" onClick={openNav}>
                      Commercial
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/maintenance" onClick={openNav}>
                      Maintenance
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  className="nav-link scrollto"
                  to="/#projects"
                  onClick={openNav}
                >
                  Projects
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link scrollto"
                  to="/#testimonials"
                  onClick={openNav}
                >
                  Clients
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link scrollto"
                  to="/#contact"
                  onClick={openNav}
                >
                  Contact Us
                </NavLink>
              </li>
            
 
            </ul>
            <i
              className={
                show
                  ? `bi bi-list mobile-nav-toggle`
                  : `bi bi-x mobile-nav-toggle`
              }
              onClick={openNav}
            ></i>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Nav;
