import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
const Projects = () => {
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);

  return (
    <>
      <section className="about" id="projects">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="section-title">
                <h1 className="bold6  colorBlue text-center text-uppercase">
                  Recent <span className="colorRed">Projects</span>
                </h1>
                 
              </div>

              <p className="justify">
                We have successfully completed a wide variety of construction
                projects ranging from home renavations, restored and repaired
                homes after damage due to disaster like fire or flooding,
                remodeled kitchens, remodeled bathrooms, and many other projects
                in and around the Triangle area. Look below to see some of our
                recently completed projects.
              </p>
            </div>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-md-4">
              <div className="projectImg">
                <img
                  src={require("../../../assets/img/projects/galleryOne/mainImg.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                  onClick={() => setOpen(true)}
                />
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={[
                    {
                      src: `${require("../../../assets/img/projects/galleryOne/1.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryOne/2.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryOne/3.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryOne/4.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryOne/5.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryOne/6.webp")}`,
                    },
                  ]}
                />
              </div>

              <div className="projectContent mt-3">
                <h5 className="colorRed text-center">
                  Project one and gallery
                </h5>
              </div>
            </div>

            <div className="col-md-4">
              <div className="projectImg">
                <img
                  src={require("../../../assets/img/projects/galleryTwo/mainImg.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                  onClick={() => setOpenOne(true)}
                />
                <Lightbox
                  open={openOne}
                  close={() => setOpenOne(false)}
                  slides={[
                    {
                      src: `${require("../../../assets/img/projects/galleryTwo/1.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryTwo/2.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryTwo/3.webp")}`,
                    },
                  ]}
                />
              </div>
              <div className="projectContent mt-3">
                <h5 className="colorRed text-center">
                  Project Two and gallery
                </h5>
              </div>
            </div>

            <div className="col-md-4">
              <div className="projectImg">
                <img
                  src={require("../../../assets/img/projects/galleryThree/mainImg.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                  onClick={() => setOpenTwo(true)}
                />
                <Lightbox
                  open={openTwo}
                  close={() => setOpenTwo(false)}
                  slides={[
                    {
                      src: `${require("../../../assets/img/projects/galleryThree/1.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryThree/2.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryThree/3.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryThree/4.webp")}`,
                    },
                    {
                      src: `${require("../../../assets/img/projects/galleryThree/5.webp")}`,
                    },
                  ]}
                />
              </div>

              <div className="projectContent mt-3">
                <h5 className="colorRed text-center">
                  Project Three and gallery
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
