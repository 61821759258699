import React from "react";
import Slider from "react-slick";
const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <>
      <section id="testimonials" className="testimonials services section-bg">
        <div className="container">
          <div className="section-title">
            <h1 className="bold6  colorBlue text-center text-uppercase">
              Client <span className="colorRed">Reviews</span>
            </h1>
             
          </div>

          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="swiper-wrapper">
              <Slider {...settings}>
                <div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <p className="">
                        <i className="bi bi-quote quote-icon-left"></i>
                        Great company to work with! They were helpful in
                        providing the best options for our new siding. They have
                        a variety of samples and made the process simple. Our
                        siding looks great and was very affordable compared to
                        other local estimates we received.
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                      <div className="bottom">
                        <img
                          src={require("../../../assets/img/testimonials/review1.webp")}
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Mary Routten</h3>
                        <h4>Ceo &amp; Founder</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        Two words - FANTASTIC EXPERIENCE. <br />
                        We had contacted several construction in the area, looked
                        through reviews, etc. and  Top Dog Contractors was BY FAR
                        the most responsive and knowledgeable with making the
                        complex basement and backyard work we needed done a
                        reality (masonry, plumbing, regrading, etc.)...
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>

                      <div className="bottom">
                        <img
                          src={require("../../../assets/img/testimonials/review2.webp")}
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Ivana Louise</h3>
                        <h4>Designer</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        Complete Bathroom Gut and remodel. Outstanding
                        performance and workmanship in all areas. I would highly
                        recommend the Top Dog team for any home remodeling
                        effort.
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                      <div className="bottom">
                        <img
                          src={require("../../../assets/img/testimonials/review3.webp")}
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Patrick D. Cothren</h3>
                        <h4>designation</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        Everyone Listen, this is Raleigh's "Best" home
                        contractor. Stays on schedule. No extra charges. No fake
                        inflation. When he says something, Believe it. The work
                        performed was excuse me but $#@+$"? Incredible. Proof is
                        in the work. He takes pride in what's done.
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>

                      <div className="bottom">
                        <img
                          src={require("../../../assets/img/testimonials/review4.webp")}
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Michael Caro</h3>
                        <h4>Digital Handler</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
