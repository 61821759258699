import React from "react";
 
const BannerBottom = () => {

  const logos=[
    {
      img:`${require("../../../assets/img/certifications/1.webp")}`
    },
    {
      img:`${require("../../../assets/img/certifications/2.png")}`
    },
     {
      img:`${require("../../../assets/img/certifications/3.png")}`
    },
    {
     img:`${require("../../../assets/img/certifications/4.png")}`
    },
    {
      img:`${require("../../../assets/img/certifications/5.png")}`
    },
   
     
  ]

  const logoTwo=[
    {
      img:`${require("../../../assets/img/certifications/6.webp")}`
    },
     {
      img:`${require("../../../assets/img/certifications/7.png")}`
    },
    {
      img:`${require("../../../assets/img/certifications/8.png")}`
    },
    {
      img:`${require("../../../assets/img/certifications/9.png")}`
    },
    {
      img:`${require("../../../assets/img/certifications/10.png")}`
    },
  ]
  return (
    <>
      <section className="bannerBottom">
        <div className="container" data-aos="zoom-in">
          <ul className="logoUl">            
            {
              logos.map((data,index)=>{
                return(
                  <li key={index}>
                  <div className="bottomImg">
                    <div>
                      <img
                        src={data.img}
                        alt="img_not_found"
                        className="img-fluid "
                      />
                    </div>
                  </div>
                </li>
                )
              })
            }
           
 
          </ul>

          <ul className="logoUl">            
            {
              logoTwo.map((data,index)=>{
                return(
                  <li key={index}>
                  <div className="bottomImg">
                    <div>
                      <img
                        src={data.img}
                        alt="img_not_found"
                        className="img-fluid "
                      />
                    </div>
                  </div>
                </li>
                )
              })
            }
           
 
          </ul>
        </div>
      </section>
    </>
  );
};

export default BannerBottom;
