import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const Carpentry = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <section className="remodaling carpenter" style={{ 'overflow-x':'hidden' }}>
        <div className="container " data-aos="fade-up" data-aos-delay="500">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1 className="text-center bannerHeading">
                OUR CARPENTRY SERVICES
              </h1>
              <p className="text-center white bannerPara">
              Our company of passionate carpenters is here to give you a glimpse of how noble and beautiful working with wood can be. Carpentry is our passion, contact the experts at  Top Dog Contractors; we use wood to create or improve indoor and outdoor spaces that are built to last.
              </p>

              
            </div>
          </div>
        </div>
      </section>

      <section className="data py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="justify">
              We work with professionals from all around the greater Triangle area, to bring you the absolute best in terms of woodworking. Contact us for any existing project, or projects still in the planning phase. We will assist, and guide you, to create the structures of your dreams.
                Top-rated professional artisans are recruited, trained, and expected to uphold the highest of standards, always. When you hire a contractor of our stature, you receive the highest quality of work. With the professionals at Top Dog Contractors, there’s no need to supervise and micromanage, your project is in good hands. No job is too big or too small, we have many years of experience in the construction industry.
              </p>
              <p className="justify">Let us share our knowledge with you and provide expert tips and tricks. We can make your small room look bigger or add additional storage to your home. Dimensions and proportions are key, and custom carpentry is exactly what you need.</p>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section  className="data">
        <div  className="container">
          {/* ======================Service One========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="kitchen">
            <div  className="col-lg-6 pt-3  ">
              <img
                src={require("../../assets/img/carpenter/kitchenRenovation.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3">
              <h2  className="headingTwo">Kitchen Renovation</h2>

              <p  className="medium justify">
              Your kitchen, "the heart of your home", is one of the most important rooms. It’ a significant space that’s used frequently, from practicing the pleasure of cooking to sharing unforgettable moments with family and friends. Given this, your kitchen must reflect your needs, personality, and lifestyle, to provide the most value possible. Unsure which kitchen style is right for you? Our skilled team can design and develop any style for homeowners in the Triangle area, such as:
              </p>

              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium m-0">Farmhouse Kitchens</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Rustic Kitchens</p>
                </li>
                <li>
                  <p  className="grey medium m-0">Modern Kitchens</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Contemporary Kitchens</p>
                </li>
                <li>
                  <p  className="grey medium m-0">Traditional Kitchens</p>
                </li>
                <li>
                  <p  className="grey medium m-0">Classic Kitchens</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Craftsman Kitchens</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Cottage Kitchens</p>
                </li>
                <li>
                  <p  className="grey medium m-0">Eco-Friendly Kitchens</p>
                </li>
                <li>
                  <p  className="grey medium m-0">Eclectic Kitchens</p>
                </li>

                 
              </ul>

             

              <div id="basement"></div>
            </div>
          </div>

          {/* ======================Service Two========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in" id="basement">
            <div  className="col-lg-6 order-last order-lg-first pt-3  ">
              <h2  className="headingTwo">Crown Molding, Installation & Repairs</h2>

              <p  className="grey medium">
              If you are looking for crown molding on top of other contractor services for your workplace or home, we are here to help you. Crown molding will add style and value to your house at a price that is well within your budget.
              </p>

              <p  className="grey medium">
              Modern and colonial styles can both be done. If you need to fix your existing moldings, we can assist you too. We are home improvement experts, specialized in crown molding and carpentry in general. Get in touch with us today to learn more about what we can do in terms of crown molding, from maintenance, to repair, paint, and even stain.
              </p>

              <p  className="grey medium">
              Interior trim work is an important aspect of every renovation or construction project. The benefits you can reap are huge in comparison to the price you will have to pay. We offer competitive prices for crown molding and other carpentry services in the Triangle area. If you have any concerns or questions, our specialists will be happy to assist you and provide you with some guidance.
              </p>

               
            </div>

            <div  className="col-lg-6 order-first order-lg-last pt-3  ">
              <img
                src={require("../../assets/img/carpenter/crownMolding.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Three========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/carpenter/finishCarpentry.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Finish Carpentry</h2>

              <p  className="grey medium">
              If you’re looking for fine and affordable finish carpentry in the Triangle area, you’re at the right place. Our crew of skilled carpenters will take care of the last few details to make your furniture look better than ever.
              </p>

              <p  className="grey medium">
              We can perform door and window installation, but also crown molding, baseboard, beam wraps, casing, stairs and much more. We’re the experts of custom woodwork and will happily answer all your questions regarding what service is needed to complete your construction or renovation project. We will even look at the work site ourselves to determine what is needed at the stage you’re at.
              </p>

              <p  className="grey medium">
              Expect quality work and results delivered in time. Running trim and moldings are what we do best. Miter and joints will be made very tight, enough to last for several years. Contact us also by phone or email for wainscoting, custom wraps, and custom columns or wall paneling. Get in touch with our design and build team for more information about our services!
              </p>
            </div>
          </div>

 {/* ======================Service Four========================= */}

          <div  className="row py-5 rowTwo"data-aos="zoom-in" >
            
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Framing Services</h2>

              <p  className="grey medium">
              Where do you start when building a new house? By the frame! And that’s when our frame carpenters come into play. The early stages of a construction project are key, and we start working from the beginning to make sure the structure is solid and built to last.
              </p>

              <p  className="grey medium">
              We take measurements, and then cut and assemble pieces of wood together for residential and commercial buildings. You can also call us to work on renovations or room additions, as well as brand new constructions.
              </p>



              <p  className="grey medium">
              Our company has all the equipment necessary to carry framing lumber and other materials. The carpenters we work with are extremely experienced and professional. They love what they do, and you can see it in their work. Framing carpentry is one of our most used services, and we would love to help customers like you build the right wood frame for your project. Contact us now for more information or fill out the inquiry form on our website.
              </p>
              

               
            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/carpenter/framing.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>




           {/* ======================Service Six========================= */}

           <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/carpenter/deck-patio.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Decks & Patios</h2>

              <p  className="grey medium">
              Building decks and patios is an art, and a passion of ours. We also build pergolas and other outdoor structures that link your interior with your backyard. We understand that a deck is a transition between two different spaces, and we work hard to make it as beautiful as possible.
              </p>

              <p  className="grey medium">
              Our team can design and build magnificent wood decks that will greatly enhance the curb appeal of your entire home, and give it a warm, welcoming feeling. All budgets are accepted, and you will be surprised that you don’t need a huge amount of money to make a big impression.
              </p>

              <p  className="grey medium">
              We love to pay attention to the little details since they often make or break your deck. We go the extra mile to build the ideal deck that will last for a long time and become your favorite space to share good times with your friends, colleagues, and family members. We’re waiting for your call and welcome all your questions. Contact us today!
              </p>
            </div>
          </div>



          {/* ======================Service Seven========================= */}

          <div  className="row py-5 rowTwo"data-aos="zoom-in" >
            
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Kitchen Cabinets, Luxury Custom-Made</h2>

              <p  className="grey medium">
              We can build any type of kitchen cabinets ranging from traditional to modern styles. You pick the style you like, and we do the rest.
              </p>

              <p  className="grey medium">
              Get your own handmade cabinets that will fit your kitchen style and space to perfection. Your kitchen will be truly unique and different from anything else you’ve ever seen. Choose the material and the type of cabinets you would like to have, and we will build it for you.
              </p>



              <p  className="grey medium">
              Hiring a carpenter to make your own custom cabinets won’t necessarily cost a lot more than purchasing them from your usual furniture store. Try our woodworking services and be impressed by the quality and level of detail we provide.
              </p>
              
              <p  className="grey medium">
              Whether you want luxury cabinets, or normal ones, we can create exactly what you want. However, if you don’t know exactly what you want, we can provide you with some ideas.
              </p>
               
            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/carpenter/kitchenCabinets.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>


   {/* ======================Service Eight========================= */}

   <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/carpenter/millwork.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Millwork</h2>

              <p  className="grey medium">
              From doors to molding, trim and flooring, our millwork services encompass a wide range of carpentry-related operations. We have our own mill and can produce a lot of different wooden products.
              </p>

              <p  className="grey medium">
              We use both common woods like oak and pine, but also uncommon kinds of wood (poplar, maple) to satisfy your needs and meet your requirements. Lengths vary depending on what you are looking for, and our millwork can be painted and stained at will.
              </p>

              <p  className="grey medium">
              Tell us what you have in mind, and we will run our machine to create it for you. Countertop fabrication or kitchen and bathroom cabinets are a few things we can do. We want your next project to be spectacular. Amaze your visitors with some carefully crafted wood pieces. Our modern machinery is here to help you do that.
              </p>
              <p  className="grey medium">
              Since millwork is an artisanal product and not always consistent, it is recommended to buy more than you need for your project in case you need to replace or repair it in the future.
              </p>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default Carpentry;
