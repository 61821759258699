import React, { useEffect } from "react";
 
import { useLocation } from "react-router-dom";

const Restoration = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <section className="remodaling restoration">
        <div className="container " data-aos="fade-up" data-aos-delay="500">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1 className="text-center bannerHeading">
                OUR RESTORATION SERVICES
              </h1>
              <p className="text-center white bannerPara">
              Emergency services and remediation is only half of the full property damage restoration process. To return your property to pre-loss condition, repairs and reconstruction services may be required. Our expert reconstruction services are designed to quickly finish the restoration of your commercial or residential property and eliminate the hassle of hiring a separate company for reconstruction services.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="data py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="justify">
              At  Top Dog Contractors, we are a full-service general contracting and disaster recovery company. We have the experience and workforce to handle everything from small repairs to total reconstruction projects. Every aspect of the reconstruction process is managed by  Top Dog Contractors. We understand the complexity of construction project management after a disaster and the sense of urgency to complete repairs as quickly as possible.
              </p>
              <p></p>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section  className="data">
        <div  className="container">
          {/* ======================Service One========================= */}

          <div  className="row py-5 rowOne "  data-aos="zoom-in"  id="kitchen">
            <div  className="col-lg-6 pt-3  ">
              <img
                src={require("../../assets/img/restoration/basement.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3">
              <h2  className="headingTwo">24/7 Water Restoration Service</h2>

              <p  className="justify">
                Water penetrates areas that are hard to see or find, and when
                not properly treated, the results can be catastrophic. We use
                the latest technology along with the highest standards to ensure
                we properly dry and treat all water damage. In addition to
                having 20 years of experience, our water damage restoration team
                participates in continued education classes at IICRC (The
                Institute of Inspection, Cleaning, and Restoration
                Certification).
              </p>

              <p  className="mainColor medium">
                <b>1) Professional</b>
              </p>

              <p  className="  medium justify">
                When you have water damage in your home from a flood or pipe
                burst, there are a number of benefits having it cleaned up by a
                professional. For one thing, professional restoration helps to
                restore your home to its previous condition, which protects the
                investment you have made. Professional restoration also has
                health benefits because it helps to prevent mold and mildew
                growth. And hiring Top Dog to do the work saves you time and
                energy.  Top Dog Contractors employees are not only professional,
                friendly and relatable, but they also focus on doing their work
                safely and providing quality. In addition, our water damage
                restoration company is available 24 hours a day, seven days a
                week, 365 days a year, so we can take care of your water
                restoration job no matter when it happens.
              </p>


              

              <div id="basement"></div>
            </div>
          </div>

          {/* ======================Service Two========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in"  id="basement">
            <div  className="col-lg-6 order-last order-lg-first pt-3  ">
              <h2  className="headingTwo">Mold Restoration Service</h2>

              <p  className="grey medium justify">
                Knowledge and experience are a must when treating mold, via
                restoration and mold encapsulation, to eliminate the risk of
                health issues. Our mold removal services team of professionals
                will properly identify, treat and remove the mold, and ensure
                the problem is contained and does not spread to other parts of
                your home or business.
              </p>

              <p  className="grey medium justify">
                {" "}
                Mold is one of those subversive property destroyers, creeping in
                thanks to water damage. Spores can start developing within 48
                hours following water damage. If left untreated it can cause
                horrible smells and ruin even the finest of homes. It often
                performs the worst damages out of sight. The work required to
                address the original water issue can be intensive. However, this
                is only a part of restoring a house to its former grandeur and
                glory.
              </p>
            </div>

            <div  className="col-lg-6 order-first order-lg-last pt-3  ">
              <img
                src={require("../../assets/img/restoration/molding.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Three========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in"  id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/restoration/fire.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Fire Restoration Service</h2>

              <p  className="grey medium justify">
                Along with the highest trained professionals in restoration, our
                fire restoration team also brings a master builder to properly
                inspect and rebuild any structure. Our construction team has
                been working in the residential and commercial building industry
                for over 20 years with an outstanding reputation.
              </p>
              <p  className="grey medium justify">
                Structures that have had a fire, need a real professional to
                rebuild them correctly. Dealing with the aftermath of a fire can
                be devastating, and it can be difficult to know where to begin.
                It can be an emotional time, and there may have been extensive
                damage to the premises. At this difficult time, it is important
                to turn to a fire restoration company that can address the issue
                of fire restoration.
              </p>

              <p  className="grey medium justify">
                We are experienced in issues of fire restoration. Our team of
                experts has successfully addressed fire restoration jobs both
                small and large. We are a full-service restoration company. We
                serve the smoke and fire damage cleanup needs of clients in
                Raleigh, Durham, Chapel Hill and all surrounding areas.{" "}
              </p>

              <p  className="grey medium justify">
                Here at Top Dog, we are available 24/7 every day of the year to
                address your fire restoration needs so that you don’t have to
                wait to start getting things back on track. Our fire restoration
                experts are committed to delivering quality work to help restore
                your home, and your life, back to normal.
              </p>
            </div>
          </div>

 {/* ======================Service Four========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in"  >
            
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Bio-Hazard Restoration Service</h2>

              <p  className="grey medium justify">
                The circumstances leading to the need for biohazard cleanup are,
                almost invariably, upsetting ones. Facing the need to restore
                the affected area can be quite stressful. It is, however, a
                necessary step to take to start getting things back to normal.
              </p>
              <p  className="grey medium justify">
                Biohazard restoration is not a task that you can take on
                yourself, nor is it a job for those who are not experts in it.
                This is something that only experienced, trained experts should
                take on. When you need biohazard restoration help, you should
                turn to our biohazard restoration services professionals.
              </p>

              <p  className="mainColor medium">
                <b>1) IICRC Firm</b>
              </p>

              <p  className="grey medium justify">
                 Top Dog Contractors is a full-service restoration company that
                provides biohazard inspection and cleaning. Our biohazard
                restoration services team is committed to restoring your home
                back to the way it was so that you can start getting your life
                back on track.
              </p>

              <p  className="grey medium justify">
                We can provide expert help. We are an IICRC firm. That means we
                have certification from the non-profit organization Institute of
                Inspection, Cleaning, and Restoration Certification, which helps
                to set industry standards.
              </p>

            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/restoration/biohazard.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>





            {/* ======================Service Five========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" data-aos-duration="600" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/restoration/pollution.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Air Duct Cleaning Service</h2>

              <p  className="grey medium justify">
                Residential and Commercial Air Duct Cleaning Service! Air ducts
                can collect harmful types of pollutants, which can be harmful to
                those breathing the air around them.
              </p>

              <p  className="grey medium justify">
                Such contaminants include dander, dust, molds/fungus, and
                bacteria. All these pollutants have been known to cause asthma
                or allergies or even in certain cases chronic diseases. Diseases
                such as emphysema, tuberculosis, or high-level sarcoidosis. With
                all of the concerned health risks that could be caused by your
                residential or commercial air-conditioning system, it is
                necessary to clean your air A.C. unit, ducts, and even furnace
                to ensure healthy air quality. Our air duct cleaning service
                technicians will take care of the hard work for you!
              </p>

              <p  className="grey medium justify">
                Regular mopping, dusting, sweeping, and vacuuming makes a
                significant difference. A deeper clean, HVAC duct cleaning, such
                as carpet steaming, washing your curtains regularly, and
                upholstery cleaning, can all improve the quality of air. The
                filter in your HVAC system also makes a very significant
                difference!{" "}
              </p>

              <p  className="grey medium justify">
                Though having your air ducts cleaned by professionals is very
                advantageous to your health and home, it isn’t always enough. To
                guarantee that you are always breathing in the air of the
                cleanest quality, we suggest you stay up to date on all your
                filters. Your air filters need to be changed at least every
                three months. If you have pets living within your home, then the
                filter should be changed every two months. Lastly, if you have
                multiple pets or suffer from allergies, you should change your
                filter every 20-45 days.
              </p>
            </div>
          </div>

          {/* ======================Service Six========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in" data-aos-duration="600">
            
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Storm Damage Service</h2>

              <p  className="grey medium justify">
                Storms are some of the most common causes of property damage.
                High winds and tornados can cause massive structural damage to
                your home or business. Raining and flooding can bring water
                damage to your home, which can destroy drywall and carpet and
                leave behind more long-term problems such as rotting wood and
                mold. Though rare, lightning strikes to the house or nearby can
                cause fires, smoke damage, and charring. And hail can bust up
                your roof, windows, and siding, leaving your home open to the
                elements.
              </p>
              <p  className="grey medium justify">
                Any kind of storm damage to your home can be devastating — it
                doesn’t matter if it comes from a tornado or a rainstorm. If a
                storm damages your property, it is important to have it cleaned
                up properly by an experienced professional.  Top Dog Contractors
                offers storm damage restoration services to homeowners in the
                triangle and all surrounding areas. When you call us, you can
                count on quality work and excellent customer service.
              </p>

            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/restoration/storm.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default Restoration;
