

export const services=[
  {
    key:1,
    title:"Remodeling",
    image:`${require("../../../assets/img/services/serviceRemodel.webp")}`,
    link:"/remodeling",
   
  },
  {
    key:2,
    title:"RESTORE",
    image:`${require("../../../assets/img/services/serviceRestore.webp")}`,
    link:"/restoration",
     
  },
  {
    key:3,
    title:"REPAIR",
    image:`${require("../../../assets/img/services/serviceRepair.webp")}`,
   
    content:"From rotten deck boards to holes in the drywall, all the nagging repairs, maintenance tasks, and any desperately needed improvements needed on your property, it’s easy to feel overburdened. Let us take weight off your shoulders.  Top Dog Contractors is the known for their outstanding REPAIR work and home improvement services. Residential and commercial properties alike can benefit from our expertise. Our service professionals have the skills and experience to ensure your property stays in the best of conditions.",
    link:"/repair",

  },
  {
    key:4,
    title:"Carpentry",
    image:`${require("../../../assets/img/services/serviceCarpentry.webp")}`,
    link:"/carpentry",
    
  },
  {
    key:5,
    title:"Maintenance",
    image:`${require("../../../assets/img/services/serviceMaintenance.png")}`,
    link:"/maintenance",
   
  },
  {
    key:6,
    title:"Commercial",
    image:`${require("../../../assets/img/services/serviceCommercial.webp")}`,
    link:"/commercial",
     
  },
 
  {
    key:7,
    title:"Smart Home",
    image:`${require("../../../assets/img/services/smarthome.jpg")}`,
    link:"/smart-home",
     
  }
]