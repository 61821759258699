import React from 'react'
import { NavLink } from 'react-router-dom';
import { services } from "./serviceData";

const Services = () => {
  return (
    <>
      <section className="services" id='services'>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className='section-title'>
              <h1 className="bold6  colorBlue text-center text-uppercase">
                Our <span className="colorRed">Services</span>
              </h1>
               
              </div>
              

              <p className="justify">
                 Top Dog Contractors continues to push the boundaries to deliver
                superior services and exceptional customer service all while
                creating value for the property owners in and around the
                Triangle Area: Raleigh, Durham, and Chapel Hill. Our team of
                experienced professionals specializes in dealing with all types
                of construction. So, whatever service you may need, Top Dog
                Contractors is here to help.
              </p>
            </div>
          </div>

          <div className="row" data-aos="fade-up">
            {services.map((data, index) => {
              return (
                <div className="col-md-4 mx-auto mt-3" key={data.key}>
                  <div className="customCard">
                    <div className="cardImg">
                      <img
                        src={data.image}
                        className="img-fluid"
                        alt="Image_not_found"
                      />
                      <h5 className="mt-3 mb-0 text-uppercase">{data.title}</h5>
                      
                       <NavLink to={data.link} className="d-flex" > <p className="colorRed  m-0">View Details<i className="bi bi-arrow-right-short"></i> </p></NavLink> 
                     
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
       </>
  )
}

export default Services