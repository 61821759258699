import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

const Commercial = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <section className="remodaling commercial">
        <div className="container " data-aos="fade-up" data-aos-delay="500">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1 className="text-center bannerHeading">
                OUR COMMERCIAL SERVICES
              </h1>
              <p className="text-center white bannerPara">
                Finding the right company to perform maintenance on your
                property is an important decision; it could mean the difference
                in receiving work far below expectations and work that increases
                the value of your property. At Top Dog Contractors, we
                understand the art of property management, and we’re well-versed
                in communicating with tenants. Our services are ideal for
                management companies, private landlords, and financial
                institutions in need of maintenance, remodels, handyman work,
                and make ready repairs.
              </p>
               
            </div>
          </div>
        </div>
      </section>

      <section className="data py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="justify">
                 Top Dog Contractors has a team of professionals dedicated to
                property preservation services. We work with banks, asset
                management companies, and more to provide services such as
                repairs, inspections, insurance claim management, and
                maintenance. Furthermore, our property preservation team cares
                for the inside and outside of foreclosed properties, servicing
                both vacant and occupied properties.
              </p>
              <p className="justify">
                We also work together with property management companies and
                financial entities ensuring their properties are safe, clean,
                and up to code. You can depend on our experienced professionals
                to help you get the most out of property ownership, management,
                or investment. We’re here to help with the “heavy lifting” of
                owning properties, so, you can enjoy low tenant turnover,
                excellent resident experiences, and a rewarding ROI
                (return-on-investment).
              </p>
              <p className="justify">
                Our main focus with small or large scale construction projects
                is to develop a clear understanding of your individual needs &
                budget. No matter how small or big your project is, we will
                strive to find a solution for your budget! Trust a company that
                understands property management and tenant communication.
                Contact Top Dog Contractors today!
              </p>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section  className="data">
        <div  className="container">
          {/* ======================Service One========================= */}

          <div  className="row py-5 rowOne " data-aos="zoom-in" id="kitchen">
            <div  className="col-lg-6 pt-3  ">
              <img
                src={require("../../assets/img/commercial/commercial-propertyManagment.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3">
              <h2  className="headingTwo">Residential Property Management</h2>

              <p  className="grey medium justify m-0">
                Are you looking to hire a dependable residential property
                manager in the greater Triangle area? Your search ends here! As
                North Carolina’s leading residential property management
                company, we provide the most comprehensive residential property
                management services in all of North Carolina.
              </p>

              <p  className="grey medium justify ">
                Top Dog Contractors has an experienced team of professionals
                dedicated to Property Management. We are your one-stop
                destination that provides all your property management needs,
                servicing the Triangle area (Raleigh, Durham, Chapel Hill) and
                beyond. Spearheaded by real estate experts, we are well-equipped
                to provide the most comprehensive property management solutions
                around.
              </p>
              <h6>
                We Offer the Following Services for Residential Property
                Management
              </h6>

              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium m-0">Tenant Screening & Evaluation</p>
                </li>

                <li>
                  <p  className="grey medium m-0">
                    Regular Inspection & Maintenance
                  </p>
                </li>
                <li>
                  <p  className="grey medium m-0">Financial Reporting</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Compliant Eviction</p>
                </li>
                <li>
                  <p  className="grey medium m-0">
                    Light Fixture Installation & Repair
                  </p>
                </li>
                <li>
                  <p  className="grey medium">Sink Installation & Repair</p>
                </li>
              </ul>
            </div>
          </div>

          {/* ======================Service Two========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in" id="basement">
            <div  className="col-lg-6 order-last order-lg-first pt-3  ">
              <h2  className="headingTwo">Lobby Renovation And Remodeling</h2>

              <p  className="grey medium justify ">
                Your building’s lobby is a common area, shared by tenants,
                visitors, and even more importantly, potential tenants. Nothing
                makes a stronger impression than a grand entrance! For condos,
                apartment buildings, and office buildings, a modern lobby design
                can increase curb appeal and add value to the property.
                Furthermore, modern lobby renovations can improve building
                security and reduce the overall maintenance cost for property
                managers.
              </p>
              <p  className="grey medium justify ">
                Redesigning and constructing building lobbies is a collaborative
                effort among tenants, property managers, and construction.
                Furthermore, the lobby should accommodate both tenants, and the
                property management’s standards.
              </p>
              <p  className="grey medium justify ">
                It can be difficult to come to a common agreement among
                management staff and tenants. Surveying residents is one method
                of creating a concept for the interior design.
              </p>

              <p  className="grey medium justify ">
                For any lobby renovation, it’s crucial to work with a contractor
                and designer that’s conscious of building codes. Also, an
                experienced architecture team is valuable in procuring a
                functional and attractive design. Top Dog Contractors provides a
                turn-key solution, no need to hire multiple companies for a
                single project.
              </p>
            </div>

            <div  className="col-lg-6 order-first order-lg-last pt-3  ">
              <img
                src={require("../../assets/img/commercial/commercial-lobby.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Three========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/commercial/commercial-makeReady.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Make Ready Solutions</h2>

              <h6  className="colorRed  ">
                <b>One Quote for the Entire Make Ready</b>
              </h6>

              <p  className="grey medium m-0 ">
                Indulge in a beautiful new bathroom by calling Top Dog
                construction about our remodeling and renovation services. We can
                replace your toilet, add new tile floors, or even install a new
                vanity. No project is too large or too small!
              </p>

              <h6  className="colorRed   ">
                <b>Quick Turnaround</b>
              </h6>

              <p  className="grey medium m-0">
                We have an experienced team of professionals dedicated solely to
                make ready services. For this reason, we provide quality
                services at a faster pace. The standard make ready service is
                complete within 48 - 72 hours. Our number on priority is to get
                your units back on the market in record time, increasing ROI.
              </p>

              <h6  className="colorRed   ">
                <b>Complete Transparency</b>
              </h6>

              <p  className="grey medium m-0">
                With our innovative technologies, you gain complete transparency
                into the entire life cycle of our make ready services, access
                provided via computer, including mobile. This provides insight
                into all work that was completed prior to new tenants moving in
              </p>

              <h6  className="colorRed ">
                <b>Preventative Maintenance</b>
              </h6>

              <p  className="grey medium m-0  ">
                Along with routine turnover maintenance,  Top Dog Contractors
                adds kitchen appliance, and HVAC, preventative maintenance to
                save you money on future repairs.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Commercial;
