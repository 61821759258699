import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

const SmartHome = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <section className="remodaling smartHome">
        <div className="container " data-aos="fade-up" data-aos-delay="500">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1 className="text-center bannerHeading">
                OUR SMART HOME SERVICES
              </h1>
              <p className="text-center white bannerPara">
                We offer smart home automation for residents and business owners
                in the greater Triangle area, specializing in high-end
                integration. We can install a complete home automation system,
                including, smart lights, smart locks, smart thermostats, smart
                blinds, smart doorbells, audio-video distribution, a security
                system, and surveillance. Everything is controlled by a tablet
                on the wall, mobile app, remote control, or by voice commands.
                We offer our customers solutions for enhancing their security,
                comfort level, and overall experience of their smart connected
                properties.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="data py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                <b>What is a Smart Home?</b>
              </p>
              <p className="justify">
                There are 2 main functions within a properly configured Smart
                Home. When properly combined they can integrate all your devices
                into one, easy to use, interface:
              </p>

              <ul className="px-3 p-md-auto">
                <li>
                  <p>Smart Home Automation</p>
                  <ul className="px-3 px-md-auto">
                    <li>
                      <p className="justify">
                        
                        Once the system is installed, your home can anticipate
                        and react to your needs, rather than adjusting each
                        system one by one to meet your needs. This opens a new
                        world of possibilities of what you can achieve with home
                        automation.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p> Smart Home Control</p>

                  <ul>
                    <li>
                      <p className="justify">
                        {" "}
                        This includes centralized control over things such as
                        Lighting, HVAC, Appliances, Audio-Visual Equipment,
                        Security Systems, Access Control, Irrigation, Pools &
                        Spas, as well as Curtains & Blinds.
                      </p>
                    </li>
                    <li>
                      <p className="justify">
                        {" "}
                        Once the smart home automation system is installed, you
                        will be able to monitor and control all your devices
                        from any smartphone or tablet. This can be done locally
                        from inside your property or from any remote location
                        via the internet.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="justify">
                Whether you’re a homeowner, general contractor, interior
                designer, or a custom home builder, we’ve got you covered. We
                will plan, design, install, and customize your smart home
                system, exceeding all expectations with our first-class customer
                support.
              </p>
              <p className="justify">
                Life is meant to be enjoyed with friends and family, so let your
                smart home take care of you instead of the other way around. We
                have gathered some of the more commonly used and frequently
                requested automations, to help our customers with getting
                started and acquainted with their very own smart home.
              </p>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section  className="data">
        <div  className="container">
          {/* ======================Service One========================= */}

          <div  className="row py-5 rowOne " data-aos="zoom-in" id="kitchen">
            <div  className="col-lg-6 pt-3  ">
              <img
                src={require("../../assets/img/smarthome/smartHome.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3">
              <h2  className="headingTwo">Custom Smart Home Solutions</h2>

              <p  className="grey medium justify m-0">
                If you are looking for the ultimate custom smart home solution –
                look no further. This is where we shine – meeting with you,
                understanding your needs, and being able to design and install a
                custom solution that will elevate your lifestyle, brings us
                great joy and satisfaction.
              </p>

              <h6>
                When designing a custom solution for you, we will be going
                through the following stages:
              </h6>

              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium m-0">Consultation</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Design</p>
                </li>
                <li>
                  <p  className="grey medium m-0">Installation & Configuration</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Training</p>
                </li>
                <li>
                  <p  className="grey medium m-0">Ongoing Support</p>
                </li>
              </ul>

              <p  className="grey medium justify m-0">
                Our focus is centered around understanding your needs and
                requirements, so, we can tailor a custom solution specifically
                for you. What you get from us is a unique, one-of-a-kind, custom
                smart home solution that is easy to use, built to last, and
                designed to be scaled. Most importantly, our custom smart home
                services will improve your overall experience and elevate your
                lifestyle!
              </p>
            </div>

            <div className="col-md-12 pt-3">
              <p  className="grey medium justify m-0">
                Integrating all the different systems on your property with our
                custom smart home solution, will help you avoid the need for
                using 8-12 different apps to control each system independently.
                Not only will you be able to control your property with a
                single, easy to use app, but all the different systems can now
                “talk” to one another. This allows for some amazing automations
                that can save you time by creating scenes that trigger many
                different devices simultaneously, saving you money by turning
                off unused lights, and making sure the temperature is set just
                right when needed.
              </p>

              <p  className="grey medium justify m-0">
                With us, you can reach a level of customization which is
                unattainable using consumer grade DIY automation systems. Best
                of all, knowing that you have a professionally installed system,
                means that we will always have your back. We will be there to
                help troubleshoot, make changes or upgrades to the system, and
                ensure all systems are running smoothly as expected.
              </p>

              <h6>
                The following systems can be integrated into your custom smart
                home solution:
              </h6>

              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium m-0">Lights & Blinds</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Audio & Video</p>
                </li>
                <li>
                  <p  className="grey medium m-0">Climate Control</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Security & Surveillance</p>
                </li>
                <li>
                  <p  className="grey medium m-0">Irrigation</p>
                </li>

                <li>
                  <p  className="grey medium m-0">Pool & Spa</p>
                </li>
                <li>
                  <p  className="grey medium m-0">And much more!</p>
                </li>
              </ul>
            </div>
          </div>

          {/* ======================Service Two========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in" id="basement">
            <div  className="col-lg-6 order-last order-lg-first pt-3  ">
              <h2  className="headingTwo">Network Infrastructure & Wi-Fi</h2>

              <p  className="grey medium justify ">
                Welcome to the 21st century, where everyone is consuming content
                on the internet, and properties are heavily reliant on bandwidth
                provided by Internet Service Providers. All too often, we find
                customers who are paying for packages with high speeds, however,
                their network is not optimized to take advantage of such speeds.
                Most were unaware of the existence of such high speeds, while
                many were already paying for it. A properly installed and
                configured network will allow your home, or business, to take
                advantage of the performance and speeds your network has to
                offer. If you properly place wireless access points at strategic
                locations about your property and acquire the necessary
                equipment to deliver and balance the usage between all your
                devices, you can avoid dead spots and slow internet speeds.
              </p>

              <p  className="grey medium justify ">
                If you are building a new home, pre-wiring is one of the most
                important and cost-effective processes one should take advantage
                of. This will allow for scalability in the future, while making
                sure your house has the proper infrastructure for all the
                different systems that are typically installed. This also sets
                your home up for the future; technology is constantly changing
                and growing rapidly, making sure you have the right cables in
                place will allow you to upgrade your equipment with the latest
                technology without major renovations.
              </p>

              <p  className="grey medium justify ">
                If you are suffering from slow internet, dropped connections, or
                you want to upgrade your network for any reason, we will be more
                than happy to provide a solution that exceeds all expectations.
              </p>
            </div>

            <div  className="col-lg-6 order-first order-lg-last pt-3  ">
              <img
                src={require("../../assets/img/smarthome/smartNetwork.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Three========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/smarthome/smartAudio.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Whole Home Audio</h2>

              <p  className="grey medium m-0 ">
                Music has become a large part of our society, it’s played in our
                homes, vehicles, and at many of the destinations we frequent. It
                can make us more productive to help us finish a task or help us
                relax after a long day at work. Music can set the mood for any
                occasion or even sooth a baby when it’s time for nap time. Music
                can affect our lives in many ways. It can improve our mood to
                reduce stress by creating the perfect atmosphere.
              </p>

              <p  className="grey medium">
                Imagine listening to your favorite tunes in the kitchen, while
                your partner is working out in the basement, listening to
                something completely different. Once you’ve been given the
                ability to control what music is played in each room in your
                home, living without it is no longer an option. Our
                professionals, dedicated to the art of smart home services, can
                help you design and install the right system for your home or
                business.
              </p>
              <h6  className="colorRed   ">
                <b>Rediscover the influence music has on our lives.</b>
              </h6>
            </div>
          </div>

          {/* ======================Service Four========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in" id="basement">
            <div  className="col-lg-6 order-last order-lg-first pt-3  ">
              <h2  className="headingTwo">Home Theatre</h2>

              <p  className="grey medium justify ">
                A home theatre is the perfect place to bring family and friends
                together. A theatre designed and installed properly will immerse
                you and your guests in a complete cinematic experience. One with
                the ability to make you leap out of your seat with excitement!
                Our team of professionals, dedicated to smart home services, can
                help create an amazing surround sound experience for your
                dedicated media room, living room, recreational room, or even a
                common area in an apartment complex or condo. Floor standing
                speakers, in-wall or in-ceiling, visible or hidden, we can make
                your dream setup a reality.
              </p>

              <p  className="grey medium justify "></p>

              <h6  className="colorRed   ">
                <b>Contact us today for a free consultation.</b>
              </h6>
            </div>

            <div  className="col-lg-6 order-first order-lg-last pt-3  ">
              <img
                src={require("../../assets/img/smarthome/smartTheatre.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>



 {/* ======================Service Five========================= */}

 <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/smarthome/smartSecurity.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Security & Surveillance</h2>

              <p  className="grey medium m-0 ">
              A home security system can be much more than just an alarm sounding if a door or window opens. When looking to enhance your current security system, installing a new system in a new build, or during renovations, we can create the ultimate security system for you.
              </p>

              <p  className="grey medium">
              Our systems include all the traditional sensors such as door/window sensors, motion detectors, smoke/CO2 detectors, as well as glass breaks and leak detectors. You will be capable of monitoring your alarm system when at your property or on the other side of the world. With our systems you receive real time notifications of all events that occur. When integrated with a custom smart home solution, you will be able to view your cameras, lock or unlock your doors, and open and close the garage, all from the same app. Your system will be outfitted with the latest technologies, which is most appreciated when it comes to the safety and wellbeing of your loved ones, especially when every second counts.
              </p>
               

              <p  className="grey medium">
              If the fire alarm goes off, we can turn on all the lights inside your property, flash the outdoor lights to help signal first responders, make sure the doors are unlocked, and turn off the HVAC circulation to decrease the spread of the fire.
              </p>

              <p  className="grey medium">
              Having an integrated security and surveillance system adds a host of benefits to you and your loved ones. It’s an invaluable addition to any home or business.
              </p>
            </div>
          </div>


        </div>
      </section>
    </>
  );
};

export default SmartHome;
