import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const Maintenance = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <section className="remodaling">
        <div className="container " data-aos="fade-up" data-aos-delay="500">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1 className="text-center bannerHeading">
                OUR MAINTENANCE SERVICES
              </h1>
              <p className="text-center white bannerPara">
                If you live in the greater Triangle area and have been searching
                for a handyman to perform maintenance around your home, or
                business, you’ve come to the right place.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="data py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="justify">
                At  Top Dog Contractors, we provide homeowners with the most
                reliable and professional maintenance in the area. No matter
                your maintenance needs, we can help. We provide a wide array of
                interior and exterior maintenance, from fixing a leaky faucet to
                hanging pictures, our maintenance professionals can get the job
                done while exceeding all expectations.
              </p>
              <p>
                 Top Dog Contractors specialize in such a wide array of home
                repairs that there’s no job too big or too small. Even better,
                our process is streamlined to ensure finding a reliable
                maintenance service in the greater Triangle area is a
                stress-free and simple experience. Call today for a free
                estimate!
              </p>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section  className="data">
        <div  className="container">



  
          {/* ======================Service Zero========================= */}



        <div  className="row py-5 rowTwo" data-aos="zoom-in" id="basement">
            <div  className="col-lg-6 order-last order-lg-first pt-3  ">
              <h2  className="headingTwo">Exterior Maintanence </h2>

              <p  className="grey medium mb-1">
              The exterior of your home protects and provides comfort inside for you and your family. The aesthetics of your home exterior makes you a proud homeowner. Ongoing maintenance on your home is important for long term ownership and protecting your precious investment.
              </p>

              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">Deck Staining and Sealing
</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                  Fence Staining and Sealing

                  </p>
                </li>

                <li>
                  <p  className="grey medium mb-1">
                  Check and Tighten All Loose Screws & Nails On Fence, Gates, and Latches

                  </p>
                </li>

                <li>
                  <p  className="grey medium mb-1">
                  Check and Tighten All Loose Screws & Nails On Fence, Gates, and Latches

                  </p>
                </li>
                
              </ul>
            </div>

            <div  className="col-lg-6 order-first order-lg-last pt-3  ">
              <img
                src={require("../../assets/img/maintenance/exteriorMaintenance.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>



          {/* ======================Service One========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="kitchen">
            <div  className="col-lg-6 pt-3  ">
              <img
                src={require("../../assets/img/maintenance/interiorMaintenance.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3">
              <h2  className="headingTwo">Interior Maintenance</h2>

              <p  className=" justify">
                Staying on top of the preventative maintenance inside your home
                can provide many advantages. This process can increase the
                safety of your home, prevent larger-scale issues that can be
                costly to repair if not well maintained, and take a lot of
                stress off your shoulders. We can help complete to-do list that
                you have not had time to get to such as:
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">
                    Caulking: Windows, Tubs, &amp; Sinks, Grout, Stone, Tile
                    Sealing, &amp; more
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Tightening Door Handles, Hinges, Hand Railings, Closet
                    Doors, Fixtures &amp; more
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Dryer Vent Cleaning</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Lock Maintenance</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Carbon Monoxide &amp; Smoke Detector Maintanence
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Maintain Hand Railings To Provide Proper Stability
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> And more...</p>
                </li>
              </ul>

              <div id="basement"></div>
            </div>
          </div>

          {/* ======================Service Two========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in" id="basement">
            <div  className="col-lg-6 order-last order-lg-first pt-3  ">
              <h2  className="headingTwo">BABY & CHILD,PROOFING </h2>

              <p  className="grey medium mb-1">
                Babyproofing your home provides peace of mind for parents,
                helping to minimize the risk of injury to your child and any
                visiting children. Ideally, you should baby-proof your home
                before your child becomes mobile and inquisitive. Keep in mind,
                mobility includes rolling, pushing backward, and trying to pull
                up — not just crawling and walking.
              </p>

              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">Baby & Pet Gate Installation</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Furniture Securing Products to Reduce Tip-Overs
                  </p>
                </li>

                <li>
                  <p  className="grey medium mb-1">
                    Child-Proofing Cabinets in Kitchens & Bathrooms
                  </p>
                </li>

                <li>
                  <p  className="grey medium mb-1">
                    Covering Electrical Outlets and Reducing Other Electrical
                    Risks
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Rearranging Any Trip Hazards</p>
                </li>
              </ul>
            </div>

            <div  className="col-lg-6 order-first order-lg-last pt-3  ">
              <img
                src={require("../../assets/img/maintenance/babyProofing.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Three========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/maintenance/bathroomMaintenance.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Bathroom Remodel & Renovation</h2>

              <p  className="grey medium mb-1">
                Indulge in a beautiful new bathroom by calling Top Dog
                construction about our remodeling and renovation services. We can
                replace your toilet, add new tile floors, or even install a new
                vanity. No project is too large or too small!
              </p>
              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">Bathroom Remodel & Renovation</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Bathroom Fan Installation & Repair
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Drywall Repair & Finishing</p>
                </li>

                <li>
                  <p  className="grey medium mb-1">Fixture Installation & Repair</p>
                </li>

                <li>
                  <p  className="grey medium mb-1">Minor Plumbing Repairs</p>
                </li>

                <li>
                  <p  className="grey medium mb-1">Toilet Repair & Installation</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Vanity & Bathroom Mirror Installation
                  </p>
                </li>
              </ul>
            </div>
          </div>

          {/* ======================Service Four========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in">
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Selling Your Home</h2>

              <p  className="grey medium mb-1">
                Preparing the interior and exterior of your home for sale can
                give it an inviting and less “lived-in” feel. I can help solve
                any issues in and around your home.
              </p>

              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">
                    Repair Cracks & Holes in Drywall
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Replace Screen Doors</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Paint Rooms</p>
                </li>

                <li>
                  <p  className="grey medium mb-1">Make Sure Doors Work Properly</p>
                </li>

                <li>
                  <p  className="grey medium mb-1">
                    Repair or Replace Individual Tile Backsplashes or Floor
                    Tiles
                  </p>
                </li>

                <li>
                  <p  className="grey medium mb-1">
                    Replace Old Caulking Around Bathtub & Sinks
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Install a New Mirror & Install Shelving
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1 ">Touch Up Outdoor Paint</p>
                </li>

                <li>
                  <p  className="grey medium mb-1">
                    Repair Cracks & Holes in Drywall
                  </p>
                </li>
              </ul>
            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/maintenance/sellingHome.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Five========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/maintenance/newHomeowner.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">New Homeowner</h2>

              <p  className="grey medium mb-1">
                Purchasing a new home is exciting but can be stressful for you
                and your family. We can alleviate the stress!
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1"> Hang Pictures &amp; Artwork</p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> TV Wall Mounting</p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Add Additional Shelving</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Install or Replace: Towel Bars, Towel Rings, Shelves, Hooks,
                    &amp; Toilet Paper Holders{" "}
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Paint Rooms</p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Replace Screen Doors</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Repair Cracks &amp; Holes in Drywall
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Replace Old Caulking Around Bathtub &amp; Sinks
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Install a New Mirror</p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Install a New Mailbox</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Repair or Replace Individual Tile Backsplashes &amp; Floor
                    Tiles
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Assemble Furniture</p>
                </li>
              </ul>
            </div>
          </div>

          {/* ======================Service Six========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in">
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Bathroom Maintenance</h2>

              <p  className="grey medium mb-1">
                Bathrooms are the central hub of activity, and you need
                everything to function properly. Other than our bed, the
                bathroom is where we essentially begin and end each day. Hire
                 Top Dog Contractors to do the repairs, everything from a tile
                replacement to caulking and sealing your tiles, tub, shower, and
                toilet.
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Bath Vent Grill Cover Repair &amp; Replacement
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Medicine Cabinet Installation &amp; Replacement
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Individual Tile Replacement</p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Tub &amp; Shower Caulking</p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Toilet &amp; Sink Caulking</p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Grout Sealing</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Shower Curtain Rod Repair &amp; Replace
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Towel Holder Repair</p>
                </li>
              </ul>
            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/maintenance/bathroomMaintenance.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Seven========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/maintenance/kitchenMaintenance.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Kitchen Maintenance</h2>

              <p  className="grey medium mb-1">
                Whether you consider yourself a chef, passionate about cooking,
                or love to have guests over for dinner – the kitchen is the most
                important room in our home where we spend lots of time, with and
                without guests. If there is something that annoys you whenever
                you’re in the kitchen, contact  Top Dog Contractors. We provide
                maintenance to fix all the loose knobs on the drawers, lack of
                shelving, and create extra space for all your dishes & utensils.
                We can help you fix any inconvenient issues, so, you can once
                again enjoy your kitchen. We look forward to hearing from you!
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">
                    Individual Back Splash Tile Replacement
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Hinge/Knob/Handle & Drawer Slider Replacements
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Cabinet Repair</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">And much more!</p>
                </li>
              </ul>
            </div>
          </div>

          {/* ======================Service Eight========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in">
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Deck, Patio, & Gazebo Maintenance</h2>

              <p  className="grey medium mb-1">
                Nothing is more relaxing than having family and friends over on
                a nice sunny day, to enjoy your home’s deck, patio, or gazebo. A
                good-looking deck, patio, or gazebo is a perfect way to bring
                out the exterior charm of your home. However, it requires upkeep
                to maintain the charm.
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1"> Painting</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Staining </p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Waterproofing</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Sealing </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Replace Cracked or Rotted Boards
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Repair Deck Railings</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Replace Popped Nails or Screws
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Hanging String Light Installation
                  </p>
                </li>
              </ul>
            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/maintenance/deckMaintenance.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Nine========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/maintenance/garageMaintenance.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Garage Maintenance</h2>

              <p  className="grey medium mb-1">
                The garage provides many uses, and it’s a place to keep cars
                secure from the outside elements, a storage area for you and
                your family’s items, where the workbench and tools reside, or
                even the storage of yard equipment.  Top Dog Contractors can help
                keep your garage organized.
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">Workbench</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Bike Rack</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Tool & Yard Equipment</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Shelving & Wire Racking Organization Installation
                  </p>
                </li>

                <li>
                  <p  className="grey medium mb-1">Peg Board Installation</p>
                </li>

                <li>
                  <p  className="grey medium mb-1">Ladder Storage Installation</p>
                </li>

                <li>
                  <p  className="grey medium mb-1">Cabinet Installation</p>
                </li>

                <li>
                  <p  className="grey medium mb-1">Painting</p>
                </li>
              </ul>
            </div>
          </div>

          {/* ======================Service Ten========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in">
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Office Maintenance</h2>

              <p  className="grey medium mb-1">
                Are you working from home? We understand the importance of
                having an area where you can focus and be productive. If you are
                looking to update your office or need help with a home office
                solution,  Top Dog Contractors is here to help.
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Shelving & Wire Rack Organization Installation
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Dual Monitor Desk Arm Support</p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Keyboard Trays</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Ergonomic Computer Desks</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Whiteboard & Glass board Installation
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    {" "}
                    Desk, Chair, & Bookshelf Installation
                  </p>
                </li>
              </ul>
            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/maintenance/officeMaintenance.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Eleven========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/maintenance/bedroomMaintenance.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Bedroom Maintenance</h2>

              <p  className="grey medium mb-1">
                Your bedroom is the place you get the precious sleep needed to
                recharge after a busy day. The bedroom should be the most
                relaxing room in your home. There is always a to-do list to
                maintain this special place. Whether you need the room repainted
                or need help putting together the perfect bedroom furniture, we
                are always here to help!
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">TV Mounting</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Painting</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Drywall Repair</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Furniture Assembly</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Bookcase or Shelf Installation</p>
                </li>
              </ul>
            </div>
          </div>

          {/* ======================Service Twelve========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in">
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Living Room Maintenance</h2>

              <p  className="grey medium mb-1">
                The living room is your place to decompress after a long day.
                It’s where you go to kick-up your feet and dive deep into your
                favorite book. It’s a place to relax, spend quality time with
                the family, or watch your favorite sports team on Television.
                Let  Top Dog Contractors make your experience even better!
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1"> TV Mounting</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Fresh Paint</p>
                </li>
                <li>
                  <p  className="grey medium mb-1"> Drywall and Hole Repair</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Hang Pictures and Art</p>
                </li>
              </ul>
            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/maintenance/livingroomMaintenance.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Thirteen========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/maintenance/diningroomMaintenance.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Dining Room Maintenance</h2>

              <p  className="grey medium mb-1">
                The dining room is a place where we go to share a meal with
                family and friends. It’s often the place where we engage in the
                most conversation. We gather in the dining room to connect, and
                it’s their where family traditions are often created. Top Dog
                construction are here to assist you with any dining room repairs.
              </p>

              <ul  className="remodelingUl">
                <li>
                  <p  className="grey medium mb-1">Drywall Repairs</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Hole Repairs</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">Staining</p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Install & Hang Pictures, Artwork, or Mirrors
                  </p>
                </li>
                <li>
                  <p  className="grey medium mb-1">
                    Build or Install Custom Shelving
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Maintenance;
