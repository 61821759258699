import React from 'react'
import $ from 'jquery';
const Contact = () => {

  const SendMain=()=>{
 
    let name = $("#contact-2-name").val();
    let email = $("#contact-2-email").val();
    let subject = $("#contact-2-subject").val();
    let message = $("#message").val();
  
    if (name === "" || email === "" || subject === "" || message === "") {
      $(".infoForm").slideDown().delay(3000).slideUp();
  
    } else {
    $(".done").slideDown().delay(2000).slideUp();
  
      $.ajax({
        method: 'POST',
        url: 'https://formsubmit.co/ajax/info@topdog.contractors',
        dataType: 'json',
        accepts: 'application/json',
        data: {
          Name: name,
          Email: email,
          Subject: subject,
          Message: message,
  
        },
        success: (data) => {
          $(".thankyou").slideDown().delay(2000).slideUp();
  
          document.getElementById('myform').reset();
  
        },
        error: (err) => {
          $(".error").slideDown().delay(3000).slideUp();
          console.log("error")
        }
      });
  
    }
    
    
    }

    const submit=(e)=>{
      e.preventDefault();
    }
  return (
    <>
    <section id="contact" className="contact about section-bg">
      <div className="container">

        <div className="section-title">
        <h1 className="bold6  colorBlue text-center text-uppercase">
              Contact <span className="colorRed">Us</span>  
            </h1>
         
        </div>

        <div className="row mt-5 justify-content-center">
          <div className="col-lg-10">
            <div className="info-wrap">
              <div className="row">
                <div className="col-lg-4 info">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p> 800 Park Offices Dr. Suite 3404, <br/>Research Triangle Park, NC 27709</p>
                </div>

                <div className="col-lg-4 info mt-4 mt-lg-0">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p><a href="mailto: info@Topdog.contractors"> info@topdog.contractors</a>                   
                   </p>
                </div>

                <div className="col-lg-4 info mt-4 mt-lg-0">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p> <a href="tel:919-373-2882">919-373-2882</a> </p>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="row mt-5 justify-content-center">
          <div className="col-lg-10">
            <form action=" " id='myform' onSubmit={submit} className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text"  className="form-control" id="contact-2-name" placeholder="Your Name" name="name" required/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="contact-2-email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="contact-2-subject" placeholder="Subject" required/>
              </div>

              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" id='message' required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>

              <div className="row">
              <div className="col-md-12">
                  <div className="infoForm p-0">
                    <p className="m-0 text-danger hide ">Please Fill All the fields</p>
                  </div>
                  <div className="done ">
                    <p className="m-0 done text-danger hide ">Please wait a while</p>
                  </div>
                  <div className="error ">
                    <p className="m-0 error text-danger hide ">Sorry! Please check your internet</p>
                  </div>

                  <div className="thankyou ">
                    <p className="m-0 thankyou text-danger hide ">Thanks for contacting. We'll get back to you very soon.</p>
                  </div>
                </div>

              </div>
              <div className="text-center"><button type="submit" onClick={SendMain}>Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>
    </>
  )
}

export default Contact