import React, { useEffect, useState } from "react";

const Banner = () => {
  const [scrol, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 110);
    });
  }, []);
  return (
    <>
      <section
        id="hero"
        className={
          scrol
            ? `d-flex align-items-center scrolled-offset`
            : `d-flex align-items-center`
        }
      >
        <div
          className="container position-relative"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <div className="row">
            <div className="col-md-12 d-flex flex-column align-items-center">
              <img
                src={require("../../assets/img/banner1.webp")}
                className="img-fluid mb-4 bannerImg"
                alt="Image_not_found"
              />
              <h1 className="text-center">Building With Vision, Quality, And Pride.</h1>
              <h1  className="text-center colorRed ">Construction You Can't Live Without.</h1>
              <p className="colorWhite text-center">
                With more than 125 years of comprehensive construction services
                experience, our consistent quality craftsmanship and trustworthy
                partnership differentiates us as one of North Carolina’s most
                trusted construction partners. We tailor our services to match
                whatever is necessary to build what matters most to you.
              </p>
              <a href="#contact" className="btn-get-started scrollto">
                Contact Us
              </a>
            </div>
          </div>
        </div>

     </section>

     <section className="bottomIcons">
     <div className="icons">
              <div className="iconContainer facebook">
                <a href="http://www.facbook.com/topdogcontractors">
                  <i className="fa-brands fa-facebook-f "></i>
                </a>
              </div>
              {/* <div className="iconContainer linkedIn">
                <a href="#">
                  <i className="fa-brands fa-linkedin-in "></i>
                </a>
              </div> */}

              <div className="iconContainer wifi">
                <a href="https://twitter.com/tdcontractors">
                <i class="fa-brands fa-twitter"></i>
                </a>
              </div>

              <div className="iconContainer phone">
                <a href="tel:919-373-2882">
                  <i className="fa-solid fa-phone "></i>
                </a>
              </div>
            </div>
     </section>
    </>
  );
};

export default Banner;
