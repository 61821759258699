import React, { useEffect } from "react";
 
import { useLocation } from "react-router-dom";

const Repair = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <section className="remodaling restoration">
        <div className="container " data-aos="fade-up" data-aos-delay="500">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1 className="text-center bannerHeading">
                OUR HOME REPAIR SERVICES
              </h1>
              <p className="text-center white bannerPara">
              Do you have holes in your drywall? Screen doors that have fallen off their tracks? If so, it is time for you to call the home repair professionals at  Top Dog Contractors. We can save you time and money by coming to your house and performing all the fixes that have been accumulating on your to-do list. We are your one-call solution for all the projects you need done around your house.

              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="data py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="justify">
                Our experienced home improvement professionals arrive at your
                home in a nationally recognized uniform, with all the tools
                needed to repair your broken fixtures, household items, or
                anything else that needs to be properly repaired. If we find
                something that is beyond repair, we will help you find and
                install a replacement that fits your home’s specifications and
                style.
              </p>
              <p></p>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section  className="data">
        <div  className="container">
          {/* ======================Service One========================= */}

          <div  className="row py-5 rowOne "  data-aos="zoom-in"  id="kitchen">
            <div  className="col-lg-6 pt-3  ">
              <img
                src={require("../../assets/img/repair/indoorRepairs.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3">
              <h2  className="headingTwo">Indoor Repairs</h2>

              <h6  className="colorRed m-0 ">
                <b>Handrail Repair</b>
              </h6>
              <p  className="grey medium justify m-0">
                When handrails are broken in your home, it’s not just an
                eyesore—it can be a safety hazard. Keep you and the ones you
                love safe.
              </p>

              <h6  className="colorRed m-0  ">
                <b>Steps Repair</b>
              </h6>
              <p  className="grey medium justify m-0">
                Broken steps can pose as a serious safety hazard to you, your
                family, and guests to your home. If you have damaged steps, give
                our team a call as soon as possible. Let us keep your home safe
                and comfortable!
              </p>

               

              <h6  className="colorRed m-0 ">
                <b>Grout Repair</b>
              </h6>
              <p  className="grey medium justify">
                If you are dealing with damaged grout in your home, our team can
                help. Don’t waste hours attempting to do it yourself—instead,
                just hand it over to the professionals. We can spruce up your
                bathroom, kitchen, and more!
              </p>

              <h6  className="colorRed m-0 ">
                <b>Furniture Repair</b>
              </h6>
              <p  className="grey medium justify">
                If the furniture in your home is starting to show its age, give
                us a call. The professional carpenters from  Top Dog Contractors
                can work with you to repair the wooden furniture in your home.
                We can also assemble new pieces!
              </p>

             
            </div>
          </div>

          {/* ======================Service Two========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in"  id="basement">
            <div  className="col-lg-6 order-last order-lg-first pt-3  ">
              <h2  className="headingTwo">Outdoor Repairs</h2>

              <h6  className="colorRed m-0 ">
                <b>Garage Door Repair</b>
              </h6>
              <p  className="grey medium justify m-0">
                Garage door struggling to open? Is it making an abnormally loud
                noise every time it shuts? Get it fixed—and get it fixed fast.
                Contact us today so we can repair your garage door and mark this
                item off your to-do list.
              </p>

              <h6  className="colorRed m-0  ">
                <b>Gutter Repair</b>
              </h6>
              <p  className="grey medium m-0 justify">
                Do you have broken gutters? Not only can this be unsightly, but
                it can also cause rainwater to be redirected, which can damage
                your home’s drywall, roof, basement, foundation, and more. Let
                us provide a fast fix!
              </p>

              <h6  className="colorRed  m-0">
                <b>Patio Repair</b>
              </h6>
              <p  className="grey medium justify m-0">
                If your patio’s handrails are becoming loose, if the boards are
                becoming too pliant, or if they support posts are no longer
                safe, it’s time to schedule some repairs with our home
                improvement professionals. Give us a call today!
              </p>

              <h6  className="colorRed m-0 ">
                <b>Deck Repair</b>
              </h6>
              <p  className="grey medium justify m-0">
                If your patio’s handrails are becoming loose, if the boards are
                becoming too pliant, or if they support posts are no longer
                safe, it’s time to schedule some repairs with our home
                improvement professionals. Give us a call today!
              </p>

              <h6  className="colorRed  m-0">
                <b>Gazebo Repair</b>
              </h6>
              <p  className="grey medium justify">
                If you notice any issues with your gazebo, don’t risk injury or
                further damage. Give us a call, and we will send out a trained
                wood repair specialist to assess any damage to your gazebo. If
                we do find an area of concern, we will provide you with a
                detailed proposal with all available repair options.
              </p>
            </div>

            <div  className="col-lg-6 order-first order-lg-last pt-3  ">
              <img
                src={require("../../assets/img/repair/outdoorRepairs.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Three========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in"  id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/repair/bathroomRepairs.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Bathroom Repairs</h2>

               

              <h6  className="colorRed m-0  ">
                <b>Plumbing Repair</b>
              </h6>

              <p  className="grey medium m-0 justify">
                Plumbing in your home not working properly? Contact Top Dog
                construction! Our home repair and improvement pros can fix leaks
                throughout your home to help you save money, preserve water, and
                prevent damage.
              </p>

              <h6  className="colorRed  m-0">
                <b>Shower Head Repair</b>
              </h6>

              <p  className="grey medium m-0 justify">
                Is your showerhead leaking? Are you showering with pressure that
                is too low? Contact  Top Dog Contractors and let our home repair
                experts fix your shower head. All of our technicians have an
                average of 10 years’ experience!
              </p>

              <h6  className="colorRed m-0 ">
                <b>Sink Repair</b>
              </h6>

              <p  className="grey medium m-0 justify">
                If your sink is leaking or if water damage has caused problems
                with mold and mildew, it is time for you to call Top Dog
                construction. We can provide exceptional and fast services that
                you can trust.
              </p>

              <h6  className="colorRed m-0">
                <b>Faucet Repair</b>
              </h6>

              <p  className="grey medium m-0 justify">
                Dripping faucets are not just annoying—they can waste as much as
                900+ Liters every single month. Save both time and water by
                contacting our home repair professionals. We can come repair or
                replace the faucet.
              </p>

              <h6  className="colorRed m-0  ">
                <b>Toilet Repair</b>
              </h6>

              <p  className="grey medium m-0 justify">
                No matter whether your toilet has a weak flush, makes a dripping
                noise, or has a tank that won’t fill up properly, you can trust
                the professionals from our team to fix it. We get the job done
                right and on time.
              </p>
            </div>
          </div>

 {/* ======================Service Four========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in"  >
            
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">Flooring Repairs</h2>

              <h6  className="colorRed m-0  ">
                <b>Flooring Repair</b>
              </h6>
              <p  className="grey medium m-0 justify">
                You don’t need to live with damaged floors. Not only are they
                unsightly, but they can be dangerous. Get them fixed properly or
                replaced entirely by contacting our home repair experts as soon
                as possible!
              </p>

              <h6  className="colorRed m-0">
                <b>Hardwood Repair</b>
              </h6>
              <p  className="grey medium m-0 justify">
                Every situation is different, but the first step is always to
                make sure that the cause of the problem is eliminated. After
                that, our Flooring Consultant can review the situation, outline
                your options, and help you make a wise choice about what to do.
              </p>

              <h6  className="colorRed m-0  ">
                <b>Tile Repair</b>
              </h6>
              <p  className="grey medium m-0 justify">
                 Top Dog Contractors can provide fast and efficient repairs for
                the tile in your home—no matter whether the tile is in your
                shower, the floor of your kitchen, or in your entryway. Call
                today to schedule a service with our team!
              </p>

              <h6  className="colorRed m-0">
                <b>Vinyl Repair</b>
              </h6>
              <p  className="grey medium m-0 justify">
                With vinyl tile, the best approach simply is to replace marred
                tiles. With sheet vinyl, eliminating damage requires fusing the
                surface or patching in new material. Small cuts and scratches
                can be permanently fused with liquid seam sealer. Call today to
                speak with our flooring specialist!
              </p>
            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/repair/flooringRepairs.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>





            {/* ======================Service Five========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" data-aos-duration="600" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/repair/windowRepairs.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Window Repairs</h2>

            
              <h5  className="colorRed m-0  ">
                <b>Window Repair</b>
              </h5>
              <p  className="grey medium m-0 justify">
                Stop struggling with windows that won’t close, windows that are
                cracked, or windows that are otherwise damaged. Contact Top Dog
                construction today to learn more about our repair services or to
                request an appointment.
              </p>

              <h5  className="colorRed m-0  ">
                <b>Window Screen Repair</b>
              </h5>
              <p  className="grey medium m-0 justify">
                Do not hesitate to contact  Top Dog Contractors as soon as
                possible if you would like to schedule repairs for your home’s
                window screens! We provide fast, efficient, and trusted repair
                services.
              </p>

              <h5  className="colorRed m-0  ">
                <b>Window Frame Repair</b>
              </h5>
              <p  className="grey medium m-0 justify">
                If window frames have suffered damage from the elements, call
                our home repair experts. We can boost your home’s curb appeal by
                providing fixes to those damaged window frames and make them
                look like new again.
              </p>

              <h5  className="colorRed m-0  ">
                <b>Screen Door Repair</b>
              </h5>
              <p  className="grey medium m-0 justify">
                If the screen door in your home is no longer opening and closing
                properly, or is otherwise broken, give our home repair experts a
                call! We back all of our workmanship with a Done Right Guarantee
                that you can trust.
              </p>
            </div>
          </div>

          {/* ======================Service Six========================= */}

         

        </div>
      </section>
    </>
  );
};

export default Repair;
