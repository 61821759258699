import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <section className="footer">

    <div className="container">
      <div className="row pt-5 pb-2">

        <div className="col-md-3 pt-3 pt-md-0">

         <NavLink to="/"> <img src={require("../../assets/img/logo.png")} alt="logo_not_found" className='footerLogo' /></NavLink>
          <p className="white mt-4">BUILDING WITH VISION, QUALITY, AND PRIDE. CONSTRUCTION YOU CAN'T LIVE WITHOUT.</p>
        </div>

        <div className="col-md-3 pt-3 pt-md-0">
          <h5 className="white mb-2">Sitemap</h5>
          <ul className="serviceUl p-0">
            <li><NavLink to="/"><i className="bi bi-arrow-right-short"></i>&nbsp;Home</NavLink></li>

            <li><NavLink to="/#about"><i className="bi bi-arrow-right-short"></i>&nbsp;About</NavLink></li>
            <li><NavLink to="/#services"><i className="bi bi-arrow-right-short"></i>&nbsp;Services</NavLink></li>
            <li><NavLink to="/#projects"><i className="bi bi-arrow-right-short"></i>&nbsp;Projects</NavLink></li>
            <li><NavLink to="/#testimonials"><i className="bi bi-arrow-right-short"></i>&nbsp;Testimonials</NavLink></li>
            <li><NavLink to="/#contact"><i className="bi bi-arrow-right-short"></i>&nbsp;Contact Us</NavLink></li>
          </ul>
        </div>

        <div className="col-md-3 pt-3 pt-md-0">
          <h5 className="white mb-2">Services</h5>
          <ul className="serviceUl p-0">
            <li><NavLink to="/remodeling"><i className="bi bi-arrow-right-short"></i>&nbsp;Remodeling</NavLink>
            </li>
            <li><NavLink to="/restoration"><i className="bi bi-arrow-right-short"></i>&nbsp;Restoration</NavLink>
            </li>
            <li><NavLink to="/repair"><i className="bi bi-arrow-right-short"></i>&nbsp;Repair Home</NavLink>
            </li>
            <li><NavLink to="/carpentry"><i className="bi bi-arrow-right-short"></i>&nbsp;Carpentry</NavLink></li>
            <li><NavLink to="/commercial"><i className="bi bi-arrow-right-short"></i>&nbsp;Commercial</NavLink></li>
            <li><NavLink to="/maintenance"><i className="bi bi-arrow-right-short"></i>&nbsp;Maintenance</NavLink></li>
            <li><NavLink to="/smart-home"><i className="bi bi-arrow-right-short"></i>&nbsp;Smart Home</NavLink></li>
          </ul>
        </div>

        
        <div className="col-md-3 pt-3 pt-md-0">
        
          <div className="social">
                  <i className="bi bi-geo-alt"></i>
                  <h5>Location:</h5>
                  <p>800 Park Offices Dr. Suite 3404, <br/>Research Triangle Park, NC 27709</p>
                </div>

            <div className=" social ">
                  <i className="bi bi-envelope"></i>
                  <h5>Email:</h5>
                  <p><a href="mailto: info@ttopDog.contractors"> info@topdog.contractors</a>                   
                   </p>
            </div>

                <div className=" social   ">
                  <i className="bi bi-phone"></i>
                  <h5>Call:</h5>
                  <p> <a href="tel:919-373-2882">919-373-2882</a> </p>
                </div>
         </div>


        







        <div className="col-md-12 mt-5">
          <p className="m-0 white text-center">© Copyright  Top Dog Contractors. All Rights Reserved</p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Footer