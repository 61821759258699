import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const Remodeling = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <section className="remodaling">
        <div className="container " data-aos="fade-up" data-aos-delay="500">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1 className="text-center bannerHeading">
                OUR REMODELING SERVICES
              </h1>
              <p className="text-center white bannerPara">
                At  Top Dog Contractors we offer entire home renovation services
                in The Triangle (Raleigh, Durham, Chapel Hill) and surrounding
                areas. We provide the best services depending on your home needs
                and requirements. Moreover, we have an experienced team of
                renovation experts that can turn your dreams into reality.
              </p>

             
            </div>
          </div>
        </div>
      </section>

      <section className="data py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="justify">
                In addition, we use the highest quality tools and materials, as
                well as the latest technology and ideas, to enhance the
                appearance of your home. We will give you the best renovation
                services and enhance the appearance of your property. If you are
                looking to enhance the level of joy and comfort a healthy living
                space provides, then we can be of great service to you. We will
                give you exceptional renovation services and enhance the overall
                appearance of your property. We can help with the entire process
                of upgrading your exterior and interior framework. The
                renovation will be customized according to your needs, desires,
                and requirements. Call today for a free estimate!
              </p>
              <p></p>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section  className="data">
        <div  className="container">
          {/* ======================Service One========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="kitchen">
            <div  className="col-lg-6 pt-3  ">
              <img
                src={require("../../assets/img/remodeling/kitchen.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3">
              <h2  className="headingTwo">Kitchen Remodel & Renovation</h2>

              <p  className=" justify">
                You deserve to have the kitchen of your dreams—and we can make
                it your reality. Call our home improvement experts today to
                learn more about your project and to learn about the different
                services we can offer.
              </p>

              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium">Kitchen Backsplash Installation</p>
                </li>

                <li>
                  <p  className="grey medium">Cabinet Installation & Repair</p>
                </li>
                <li>
                  <p  className="grey medium">Countertop Installation & Repair</p>
                </li>

                <li>
                  <p  className="grey medium">Tile Installation & Repair</p>
                </li>
                <li>
                  <p  className="grey medium">Light Fixture Installation & Repair</p>
                </li>
                <li>
                  <p  className="grey medium">Sink Installation & Repair</p>
                </li>
              </ul>

              <div id="basement"></div>
            </div>
          </div>

          {/* ======================Service Two========================= */}

          <div  className="row py-5 rowTwo" data-aos="zoom-in" id="basement">
            <div  className="col-lg-6 order-last order-lg-first pt-3  ">
              <h2  className="headingTwo">Basement Remodel & Renovation</h2>

              <p  className="grey medium">
                Give your kids more space to play, add a new area to entertain
                guests, or bolster storage with upgrades to your basement! From
                installing an entertainment center to fixing the drywall, we’re
                the pros to call.
              </p>

              <ul className="remodelingUl">
                <li>
                  <p  className="grey medium">
                    Helping You Revamp Your Basement Area
                  </p>
                </li>
                <li>
                  <p  className="grey medium">Custom Shelving & Bookcases</p>
                </li>

                <li>
                  <p  className="grey medium">Sump Pump Replacement</p>
                </li>

                <li>
                  <p  className="grey medium">Handrail & Stairs Installation</p>
                </li>

                <li>
                  <p  className="grey medium">TV Wall Mount</p>
                </li>
              </ul>
            </div>

            <div  className="col-lg-6 order-first order-lg-last pt-3  ">
              <img
                src={require("../../assets/img/remodeling/basement.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>

          {/* ======================Service Three========================= */}

          <div  className="row py-5 rowOne" data-aos="zoom-in" id="bathroom">
            <div  className="col-lg-6 pt-3 ">
              <img
                src={require("../../assets/img/remodeling/bathroom.webp")}
                 className="img-fluid leftImg"
                alt="Image_not_found"
              />
            </div>
            <div  className="col-lg-6 pt-3 ">
              <h2  className="headingTwo">Bathroom Remodel & Renovation</h2>

              <p  className="grey medium">
                Indulge in a beautiful new bathroom by calling Top Dog
                construction about our remodeling and renovation services. We can
                replace your toilet, add new tile floors, or even install a new
                vanity. No project is too large or too small!
              </p>
              <ul className="remodelingUl">
                <li>
                   
                  <p  className="grey medium">
                    Bathroom Remodel & Renovation 
                  </p>
                </li>
                <li>
                   
                  <p  className="grey medium">
                     Bathroom Fan Installation & Repair 
                  </p>
                </li>
                <li>
                  <p  className="grey medium">
                     Drywall Repair & Finishing 
                  </p>
                </li>

                <li>
                  <p  className="grey medium">
                     Fixture Installation & Repair 
                  </p>
                </li>

                <li>
                  <p  className="grey medium">
                     Minor Plumbing Repairs 
                  </p>
                </li>

                <li>
                  <p  className="grey medium">
                     Toilet Repair & Installation 
                  </p>
                </li>
                <li>
                  <p  className="grey medium">
                     Vanity & Bathroom Mirror Installation 
                  </p>
                </li>
              </ul>
            </div>
          </div>

 {/* ======================Service Four========================= */}

          <div  className="row py-5 rowTwo"data-aos="zoom-in" >
            
            <div  className="col-lg-6 order-last order-lg-first pt-3 ">
              <h2  className="headingTwo">All Other Remodels</h2>

              <p  className="grey medium">
                Just because you do not see a specific room or service listed on
                our website does not mean we can’t handle it. Just give our home
                repair and improvement professionals a call today to discuss
                your remodeling project!
              </p>
              <p  className="mainColor medium">
                <b>Call Today for a Free Estimate!</b>
              </p>

              <p  className="grey medium">
                If you're inspired to upgrade an area of your home, it's time
                you called  Top Dog Contractors. From installing a new floor in
                your bathroom to replacing the appliances in your kitchen, we
                can do it all, done right and on time. Contact us today for a
                free estimate!
              </p>
            </div>
            <div  className="col-lg-6 order-first order-lg-last pt-3 ">
              <img
                src={require("../../assets/img/remodeling/renovation.webp")}
                 className="img-fluid rightImg"
                alt="Image_not_found"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Remodeling;
