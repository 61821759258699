import React, { useEffect } from "react";
import Aos from "aos";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import Restoration from "./components/pages/Restoration";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./components/pages/home/Landing";
import Nav from "./components/elements/Nav";
import Footer from "./components/elements/Footer";
import Remodeling from "./components/pages/Remodeling";
import Repair from "./components/pages/Repair";
import Carpentry from "./components/pages/Carpentry";
import Commercial from "./components/pages/Commercial";
import Maintenance from "./components/pages/Maintenance";
import SmartHome from "./components/pages/SmartHome";
function App() {
  useEffect(() => {
    
    Aos.init({
      duration: 2000,
  //     disable: function() {
  //    var maxWidth = 1192;
  //    return window.innerWidth < maxWidth;
  // }
    });
  }, []);
  return (
    <>
      <BrowserRouter>
      <Nav/>
        <Routes>
          <Route exact   path="/" element={<Landing />} />
          <Route  exact  path="/remodeling" element={<Remodeling />} />
          <Route  exact  path="/restoration" element={<Restoration />} />
          <Route  exact  path="/repair" element={<Repair />} />
          <Route  exact  path="/carpentry" element={<Carpentry />} />
          <Route  exact  path="/commercial" element={<Commercial />} />
          <Route  exact  path="/maintenance" element={<Maintenance />} />
          <Route  exact  path="/smart-home" element={<SmartHome />} />
        </Routes>

        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
