import React from "react";

const About = () => {
  return (
    <>
      <section className="about" id="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h1 className="bold6  colorBlue text-center text-uppercase">
              About <span className="colorRed">Top Dog Contractors</span> 
            </h1>
             
          </div>
          <div className="row">
            <div className="order-last order-md-first col-lg-6 mt-3 d-flex flex-column justify-content-center">
              <h1 className="bold6 colorBlue text-uppercase">
                You Dream It. <span className="colorRed">We Build It.</span>
              </h1>
              <p className="justify">
                <i>
                  Based in the Research Triangle Park, We Provide the Finest
                  Construction Found in the Residential and Commercial Sector.
                  Located Just Minutes From the Raleigh-Durham International
                  Airport, We Serve the Triangle and All Surrounding Areas.
                </i>
              </p>

              <p className="justify">
                 Top Dog Contractors is a full-service contracting company
                focused on residential and commercial construction. With more
                than a decade of experience, we have built a solid reputation
                for great prices, quality work, and superior service. Our
                on-going commitment to excellence and craftsmanship has been the
                driving force behind our success. As dedicated professionals we
                are committed to our craft. We regularly strive to improve and
                develop innovative solutions to our clients’ construction
                projects by being big picture oriented and detail conscious. We
                realize the importance of our customers' timeframes and make
                decisions with those values in mind. We often brainstorm
                throughout our team to take challenges and turn them into
                opportunities for creative solutions. We are committed to
                delivering superior quality construction, impeccable services,
                and exceeding the expectations of each and every client.
              </p>
            </div>

            <div className="order-first order-md-last col-lg-6 mt-3  ">
              <div className="aboutImg">
                <img
                  src={require("../../../assets/img/about/dog.webp")}
                  className="img-fluid"
                  alt="Image_not_found"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="missionVission">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-lg-4  d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <div className="icon">
                  <img
                    src={
                      require("../../../assets/icons/engineer-svgrepo-com.svg")
                        .default
                    }
                    alt="not_found"
                  />
                </div>
                
                  <h6 className="title">
                    <b>Quality Construction</b>
                  </h6>
                
                <p className="description">
                  Quality in construction can be defined as the attainment of
                  acceptable levels of performance from construction activities.
                  <br /> We build to surpass what's acceptable!
                </p>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon-box icon-box-cyan">
                <div className="icon">
                  <img
                    src={
                      require("../../../assets/icons/engineer-worker-svgrepo-com.svg")
                        .default
                    }
                    alt="mot_found"
                  />
                </div>
                 
                  <h6 className="title">
                    <b>Professional Liability</b>
                  </h6>
                 
                <p className="description">
                  Professional liability and insurance policies cover any
                  damages that arise from the rendering of contruction services.
                  <br /> We guarantee all services provided.
                </p>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4 mx-auto d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="icon-box icon-box-pink">
                <div className="icon">
                  <img
                    src={
                      require("../../../assets/icons/engineer-svgrepo-com.svg")
                        .default
                    }
                    alt="not_found"
                  />
                </div>
              
                  <h6 className="title">
                    <b>Dedicated To Our Clients</b>
                  </h6>
               
                <p className="description">
                Client satisfaction is our number one priority. From small projects to large project, both residential and commercial <br /> we work like dogs to exceed all expectations!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
