import React, {useEffect} from "react";
import Banner from "../../elements/Banner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerBottom from "./BannerBottom";
import About from "./About";
import Services from "./Services";
import Projects from "./Projects";
import Testimonial from "./Testimonial";
import Contact from "./Contact";
import { useLocation } from "react-router-dom";
 
const Landing = () => {


  let location = useLocation()
  useEffect(()=> {
        if (location.hash) {
          let elem = document.getElementById(location.hash.slice(1))
          if(elem) {
            elem.scrollIntoView({behavior: "smooth"})
          }
          
            } else {
      window.scrollTo({top:0,left:0, behavior: "smooth"})
      }
  }, [location,])
 
  return (
    <>
     
      <Banner />
      <BannerBottom/>
      <About/>
      <Services/>
      <Projects/>
     <Testimonial/>
     <Contact/>
    

     
    </>
  );
};

export default Landing;
